import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Menu } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import Icon from 'components/util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SIDE_NAV_LIGHT } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import { signOutAs } from 'redux/actions/Auth';
import { LogoutOutlined, QuestionCircleOutlined, setTwoToneColor } from '@ant-design/icons';
import { SIGNIN_AS } from '../../redux/constants/Auth';
import userService from '../../services/UserService';

const { useBreakpoint } = Grid;

const setDefaultOpen = key => {
    let keyList = [];
    let keyString = '';
    if (key) {
        const arr = key.split('-');
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const SideNavContent = props => {
    const { sideNavTheme, routeInfo, hideGroupTitle, onMobileNavToggle } = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
    const dispatch = useDispatch();
    const { user, company } = useSelector(state => {
        return state.auth;
    });
    const theme = useSelector(state => state.theme);

    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false);
        }
    };

    setTwoToneColor('#f9105e');

    let menuItems = [];

    const userRole = userService.getRoleType(user.roles);

    // check if the partner has a partner-chain connected to it that uses partner invoice
    let showPartnerInvoice = true;
    if (company?.partnerChains) {
        company.partnerChains.forEach(chain => {
            if (chain.usePartnerInvoice) {
                showPartnerInvoice = false;
            }
        });
    }

    if (typeof navigationConfig[userRole] !== 'undefined') {
        navigationConfig[userRole].forEach(menu => {
            if (menu.in_menu) {
                // custom for partner -> remove menu item if the partner-chain connected to it has partner invoice
                if (userRole === 'partner' && !showPartnerInvoice && menu.key === 'invoices') {
                    return;
                }
                if (
                    userRole === 'partnerChains' &&
                    !company?.usePartnerInvoice &&
                    menu.key === 'invoices'
                ) {
                    return;
                }

                // validate if the navigation has security
                if (menu.security?.allow) {
                    if (!userService.isAllowed(user, menu.security.allow)) {
                        return;
                    }
                }

                let label = [<IntlMessage key="link" id={menu.title} />];
                if (menu.path) {
                    label = [
                        <Link key={'link'} to={menu.path}>
                            <IntlMessage id={menu.title} />
                        </Link>,
                    ];
                }
                if (menu.badge) {
                    label.push(
                        <span className={'ml-2'} key={'badge'}>
                            {menu.badge}
                        </span>
                    );
                }

                let menuItem = {
                    key: menu.key,
                    label: label,
                    icon: <Icon type={menu.icon}></Icon>,
                };
                if (menu.submenu?.length > 0) {
                    let children = [];
                    menu.submenu.forEach(subitem => {
                        if (subitem.in_menu) {
                            let childLabel = [
                                <Link key={`child-label`} to={subitem.path}>
                                    <IntlMessage id={subitem.title} />
                                </Link>,
                            ];
                            if (subitem.badge) {
                                childLabel.push(
                                    <span className={'ml-2'} key="child-badge">
                                        {subitem.badge}
                                    </span>
                                );
                            }

                            children.push({
                                key: subitem.key,
                                label: childLabel,
                                icon: <Icon type={subitem.icon}></Icon>,
                                // path: subitem.path
                            });
                        }
                    });
                    if (children.length > 0) {
                        menuItem.children = children;
                    }
                }
                menuItems.push(menuItem);
            }
        });
    }

    if (localStorage.getItem(SIGNIN_AS)) {
        menuItems.push({
            key: 'logout_as_admin',
            label: (
                <span onClick={() => dispatch(signOutAs())}>
                    <IntlMessage id={'common.logout_as_admin'} />
                </span>
            ),
            danger: true,
            icon: <LogoutOutlined />,
        });
    }

    return (
        <div>
            <Menu
                theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
                mode="inline"
                onClick={() => closeMobileNav()}
                style={{ height: '100%', borderRight: 0, paddingTop: 20 }}
                defaultSelectedKeys={[routeInfo?.key]}
                defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
                className={hideGroupTitle ? 'hide-group-title' : ''}
                items={menuItems}
            />
            {userService.getRoleType(user.roles) === 'partner' && !theme.navCollapsed ? (
                <div className={'pl-3'}>
                    <Button
                        className={'mt-3 mb-3'}
                        onClick={() =>
                            window.open('/docs/Workit-Zo-werkt-ons-portaal.pdf', '_blank')
                        }
                        icon={<QuestionCircleOutlined />}>
                        <span>
                            <IntlMessage id={'common.manual'} />
                        </span>
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

const MenuContent = props => {
    return <SideNavContent {...props} />;
};

const mapStateToProps = ({ theme, auth }) => {
    const { sideNavTheme, topNavColor } = theme;
    const { user } = auth;
    return { sideNavTheme, topNavColor, user };
};

export default connect(mapStateToProps, { onMobileNavToggle, signOutAs })(MenuContent);
