import antdEnUS from 'antd/es/locale/en_US';
import enMsg from '../locales/en_US.json';

const EnLang = {
    antd: antdEnUS,
    locale: 'en-US',
    messages: {
        ...enMsg,
    },
};
export default EnLang;
