import {
    AUTH_SCOPE,
    AUTH_TOKEN,
    AUTH_USER_TYPE,
    AUTHENTICATED,
    HIDE_AUTH_MESSAGE,
    SCOPE,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN,
    SIGNIN_AS,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
} from '../constants/Auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    user: null,
    company: null,
    signin_as: localStorage.getItem(SIGNIN_AS),
    token: localStorage.getItem(AUTH_TOKEN),
    scope: localStorage.getItem(AUTH_SCOPE),
    user_type: localStorage.getItem(AUTH_USER_TYPE),
};

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                token: action.token,
            };
        case SIGNIN:
            return {
                ...state,
                user: action.user,
                company: action.company,
                scope: action.scope,
            };
        case SIGNIN_AS:
            return {
                ...state,
                user: action.user,
                company: action.company,
                scope: action.scope,
            };
        case SCOPE:
            return {
                ...state,
                scope: action.scope,
            };
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false,
            };
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            };
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                company: null,
                token: null,
                redirect: '/',
                loading: false,
            };
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                token: action.token,
            };
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        default:
            return state;
    }
};

export default auth;
