import { useState } from 'react';
import fetch from 'auth/FetchInterceptor';
import axios from 'axios';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { API_LIMIT } from '../constants/ApiConstant';

/**
 *
 * @param {string} url
 * @param {{
 *     pagination: {},
 *     query: {}
 * }} config
 * @returns {{isLoading: boolean, fetchData: ((function(*, *): Promise<void>)|*), data: Array|Object|null, error:string|null, totalItems: number, refresh: (function(): void)}}
 */
export default function useFetch(url, config = {}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [totalItems, setTotalItems] = useState(0);

    const cancelTokenSource = axios.CancelToken.source();

    /**
     * Fetch data from API
     * @param url
     * @param params
     * @returns {Promise<void>}
     */
    const fetchData = async (url, params = {}) => {
        setIsLoading(true);
        try {
            let axiosParams = {
                t: Date.now(),
            };

            if (params.pagination) {
                if (params.pagination.order) {
                    axiosParams[`order[${params.pagination.order}]`] = params.pagination.waypoint
                        ? params.pagination.waypoint
                        : 'asc';
                }
                axiosParams['limit'] = params.pagination.pageSize
                    ? params.pagination.pageSize
                    : API_LIMIT;
                axiosParams['page'] = params.pagination.currentPage
                    ? params.pagination.currentPage
                    : 1;
            }

            if (params.query) {
                Object.keys(params.query).forEach(key => {
                    axiosParams[key] = params.query[key];
                });
            }

            const response = await fetch({
                url: url.indexOf('api') < 0 ? `/api/${url}` : url,
                params: axiosParams,
                cancelToken: cancelTokenSource.token,
            });
            if (response) {
                if (response['hydra:member']) {
                    setData(response['hydra:member']);
                    setTotalItems(response['hydra:totalItems']);
                } else {
                    setData(response);
                    setTotalItems(1);
                }
            }
        } catch (error) {
            setError(
                error.response?.data ? error.response.data['hydra:description'] : error.message
            );
        }
        setIsLoading(false);
    };

    /**
     * Fetch data when url or config changes
     */
    useDeepCompareEffect(() => {
        if (url) {
            fetchData(url, config).catch(e => {
                setError(e);
            });
        }
        return () => {
            cancelTokenSource.cancel();
        };
    }, [url, config]);

    const refresh = () => {
        fetchData(url, config).catch(e => {
            setError(e);
        });
    };

    return {
        data,
        isLoading,
        error,
        fetchData,
        totalItems,
        refresh,
    };
}
