import React from 'react';
import useBodyClass from './useBodyClass';
import { DIR_LTR } from '../constants/ThemeConstant';

export const withBodyClass = Component => {
    return props => {
        useBodyClass(`dir-${DIR_LTR}`);
        return <Component {...props} />;
    };
};
