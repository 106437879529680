import {
    ApiOutlined,
    BookOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    ClusterOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    EuroOutlined,
    GiftOutlined,
    HomeOutlined,
    IdcardOutlined,
    ImportOutlined,
    LockOutlined,
    PaperClipOutlined,
    RocketOutlined,
    ScheduleOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    SketchOutlined,
    TagOutlined,
    TagsOutlined,
    TeamOutlined,
    TrophyOutlined,
    UsergroupAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import RefundBadge from '../components/shared-components/RefundBadge';

const navigationConfig = {
    admin: [
        {
            key: 'home',
            component: import('../views/admin-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'common.home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'profile_edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'common.profile',
            seo_title: 'Profiel bewerken',
            icon: UserOutlined,
            breadcrumb: false,
            in_menu: false,
            submenu: [],
        },
        {
            key: 'bookings',
            component: import('../views/admin-views/bookings/index'),
            path: `${APP_PREFIX_PATH}/bookings`,
            title: 'common.bookings',
            seo_title: 'Boekingen',
            icon: CalendarOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'partners',
            component: import('../views/admin-views/partners/index'),
            path: `${APP_PREFIX_PATH}/partners`,
            title: 'common.partners',
            seo_title: 'Sportlocaties',
            icon: HomeOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'partners_add',
                    component: import('../views/admin-views/partners/add'),
                    path: `${APP_PREFIX_PATH}/partners/add`,
                    title: 'admin.partners.add_partner',
                    seo_title: 'Sportlocatie toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'partners_edit',
                    component: import('../views/admin-views/partners/edit'),
                    path: `${APP_PREFIX_PATH}/partners/edit/:id`,
                    title: 'admin.partners.edit_partner',
                    seo_title: 'Sportlocatie bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'lesson_overwrite_add',
                    component: import('../views/admin-views/lesson-overwrite-column-value/add'),
                    path: `${APP_PREFIX_PATH}/partners/:id/lesson-overwrite-column-values/create`,
                    title: 'admin.lesson_overwrite_columns.add_title',
                    seo_title: 'Lesconfiguratie toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: false,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'lesson_overwrite_edit',
                    component: import('../views/admin-views/lesson-overwrite-column-value/edit'),
                    path: `${APP_PREFIX_PATH}/partners/lesson-overwrite-column-values/edit/:id`,
                    title: 'admin.lesson_overwrite_columns.edit_title',
                    seo_title: 'Lesconfiguratie bewerken',
                    icon: HomeOutlined,
                    breadcrumb: false,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'lesson_overwrite_index',
                    component: import('../views/admin-views/lesson-overwrite-column-value'),
                    path: `${APP_PREFIX_PATH}/partners/:id/lesson-overwrite-column-values`,
                    title: 'admin.lesson_overwrite_columns.index',
                    seo_title: 'Lesconfiguratie',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'partnerChains',
            component: import('../views/admin-views/partner-chains/index'),
            path: `${APP_PREFIX_PATH}/partner-chains`,
            title: 'common.partner_chains',
            seo_title: 'Sportlocatie-ketens',
            icon: ClusterOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'partners_add',
                    component: import('../views/admin-views/partner-chains/add'),
                    path: `${APP_PREFIX_PATH}/partner-chains/add`,
                    title: 'admin.partner_chains.title_add',
                    seo_title: 'Keten toevoegen',
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'partners_edit',
                    component: import('../views/admin-views/partner-chains/edit'),
                    path: `${APP_PREFIX_PATH}/partner-chains/edit/:id`,
                    title: 'admin.partner_chains.title_edit',
                    seo_title: 'Keten bewerken',
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'lessons',
            title: 'common.lessons',
            seo_title: 'Lessen',
            icon: TrophyOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'lessons_index',
                    component: import('../views/admin-views/lessons/index'),
                    path: `${APP_PREFIX_PATH}/lessons/index`,
                    title: 'common.lessons',
                    seo_title: 'Lessen',
                    icon: TrophyOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
                {
                    key: 'lessons_add',
                    component: import('../views/admin-views/lessons/add'),
                    path: `${APP_PREFIX_PATH}/lessons/add`,
                    title: 'admin.lessons.title_add',
                    seo_title: 'Les toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'lessons_edit',
                    component: import('../views/admin-views/lessons/edit'),
                    path: `${APP_PREFIX_PATH}/lessons/edit/:id`,
                    title: 'admin.lessons.title_edit',
                    seo_title: 'Les bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'lessons_types',
                    component: import('../views/admin-views/lesson-types/index'),
                    path: `${APP_PREFIX_PATH}/lessons/types`,
                    title: 'admin.lesson_types.title',
                    seo_title: 'Lestypes',
                    icon: TagOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
                {
                    key: 'lessons_type_mapping',
                    component: import('../views/admin-views/lesson-type-mapping/index'),
                    path: `${APP_PREFIX_PATH}/lessons/lesson-type-mapping`,
                    title: 'admin.lesson_type_mapping.title_index',
                    seo_title: 'Lestype vervanging',
                    icon: TagsOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
            ],
        },
        {
            key: 'locations',
            component: import('../views/admin-views/locations/index'),
            path: `${APP_PREFIX_PATH}/locations`,
            title: 'common.locations',
            seo_title: 'Locaties',
            icon: BookOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'locations_add',
                    component: import('../views/admin-views/locations/add'),
                    path: `${APP_PREFIX_PATH}/locations/add`,
                    title: 'admin.locations.title_add',
                    seo_title: 'Locatie toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'locations_edit',
                    component: import('../views/admin-views/locations/edit'),
                    path: `${APP_PREFIX_PATH}/locations/edit/:id`,
                    title: 'admin.locations.title_edit',
                    seo_title: 'Locaties bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'users',
            title: 'common.users',
            seo_title: 'Gebruikers',
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'users-index',
                    component: import('../views/admin-views/users/index'),
                    path: `${APP_PREFIX_PATH}/users/index`,
                    title: 'common.users',
                    seo_title: 'Gebruikers',
                    icon: UsergroupAddOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
                {
                    key: 'users_edit',
                    component: import('../views/admin-views/users/edit'),
                    path: `${APP_PREFIX_PATH}/users/edit/:id`,
                    title: 'admin.users.title_edit',
                    seo_title: 'Gebruiker bewerken',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'user_subscriptions',
                    component: import('../views/admin-views/users/subscriptions'),
                    path: `${APP_PREFIX_PATH}/users/subscriptions/:id`,
                    title: 'admin.users.title_subscriptions_edit',
                    seo_title: 'Abonnementen bewerken',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'user_check_ins',
                    component: import('../views/admin-views/users/checkIn'),
                    path: `${APP_PREFIX_PATH}/users/check-in`,
                    title: 'admin.users.title_check_in',
                    seo_title: 'Check-in status',
                    icon: CheckCircleOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
                {
                    key: 'bab_vouchers',
                    component: import('../views/admin-views/users/vouchers'),
                    path: `${APP_PREFIX_PATH}/users/bab-vouchers`,
                    title: 'admin.users.bab_vouchers',
                    seo_title: 'BAB-vouchers',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
            ],
        },
        {
            key: 'companies',
            component: import('../views/admin-views/companies/index'),
            path: `${APP_PREFIX_PATH}/companies`,
            title: 'common.companies',
            seo_title: 'Bedrijven',
            icon: IdcardOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'companies_add',
                    component: import('../views/admin-views/companies/add'),
                    path: `${APP_PREFIX_PATH}/companies/add`,
                    title: 'admin.companies.title_new',
                    seo_title: 'Bedrijf toevoegen',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'companies_edit',
                    component: import('../views/admin-views/companies/edit'),
                    path: `${APP_PREFIX_PATH}/companies/edit/:id`,
                    title: 'admin.companies.title_edit',
                    seo_title: 'Bedrijf bewerken',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'credits',
            component: import('../views/admin-views/credits/index'),
            path: `${APP_PREFIX_PATH}/credits`,
            title: 'admin.credits.title_index',
            seo_title: 'Credits',
            icon: SketchOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'credits_add',
                    component: import('../views/admin-views/credits/add'),
                    path: `${APP_PREFIX_PATH}/credits/add`,
                    title: 'admin.credits.title_add',
                    seo_title: 'Credits toevoegen',
                    icon: SketchOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'credits_edit',
                    component: import('../views/admin-views/credits/edit'),
                    path: `${APP_PREFIX_PATH}/credits/edit/:id`,
                    title: 'admin.credits.title_edit',
                    seo_title: 'Credits bewerken',
                    icon: SketchOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'finances',
            component: null,
            title: 'common.finances',
            seo_title: 'Financiën',
            icon: CreditCardOutlined,
            breadcrumb: true,
            in_menu: true,
            badge: <RefundBadge />,
            submenu: [
                {
                    key: 'refunds',
                    component: import('../views/admin-views/refunds/index'),
                    path: `${APP_PREFIX_PATH}/finances/refunds`,
                    title: 'common.refunds',
                    seo_title: 'Cashbacks',
                    icon: GiftOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    badge: <RefundBadge />,
                    submenu: [],
                },
                {
                    key: 'invoices_import',
                    component: import('../views/admin-views/invoices/importSpecifications'),
                    path: `${APP_PREFIX_PATH}/finances/invoices/import`,
                    title: 'admin.invoices.title_import',
                    seo_title: 'Specificaties importeren',
                    icon: ImportOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'invoices',
                    component: import('../views/admin-views/invoices/index'),
                    path: `${APP_PREFIX_PATH}/finances/invoices`,
                    title: 'admin.invoices.title_index',
                    seo_title: 'Specificaties',
                    icon: EuroOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
                {
                    key: 'payments',
                    component: import('../views/admin-views/payments/index'),
                    path: `${APP_PREFIX_PATH}/finances/payments`,
                    title: 'admin.payments.title',
                    seo_title: 'Betalingen',
                    icon: CreditCardOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: [],
                },
            ],
        },
        {
            key: 'subscriptions',
            component: import('../views/admin-views/subscriptions/index'),
            path: `${APP_PREFIX_PATH}/subscriptions`,
            title: 'common.subscriptions',
            seo_title: 'Abonnementen',
            icon: ShoppingCartOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'subscriptions_add',
                    component: import('../views/admin-views/subscriptions/add'),
                    path: `${APP_PREFIX_PATH}/subscriptions/add`,
                    title: 'admin.subscriptions.title_add',
                    seo_title: 'Abonnement toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'subscriptions_edit',
                    component: import('../views/admin-views/subscriptions/edit'),
                    path: `${APP_PREFIX_PATH}/subscriptions/edit/:id`,
                    title: 'admin.subscriptions.title_edit',
                    seo_title: 'Abonnement bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'administrators',
            component: import('../views/admin-views/administrators/index'),
            path: `${APP_PREFIX_PATH}/administrators`,
            title: 'common.administrators',
            seo_title: 'Administrators',
            icon: LockOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'administrators_add',
                    component: import('../views/admin-views/administrators/add'),
                    path: `${APP_PREFIX_PATH}/administrators/add`,
                    title: 'admin.administrators.title_add',
                    seo_title: 'Administrator toevoegen',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'administrators_edit',
                    component: import('../views/admin-views/administrators/edit'),
                    path: `${APP_PREFIX_PATH}/administrators/edit/:id`,
                    title: 'admin.administrators.title_edit',
                    seo_title: 'Administrator bewerken',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'connectors',
            component: import('../views/admin-views/connectors/index'),
            path: `${APP_PREFIX_PATH}/connectors`,
            title: 'common.connectors',
            seo_title: 'Connectoren',
            icon: ApiOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'connectors_add',
                    component: import('../views/admin-views/connectors/add'),
                    path: `${APP_PREFIX_PATH}/connectors/add`,
                    title: 'admin.connectors.title_add',
                    seo_title: 'Connector toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'connectors_edit',
                    component: import('../views/admin-views/connectors/edit'),
                    path: `${APP_PREFIX_PATH}/connectors/edit/:id`,
                    title: 'admin.connectors.title_edit',
                    seo_title: 'Connector bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'news',
            component: import('../views/admin-views/news/index'),
            path: `${APP_PREFIX_PATH}/news`,
            title: 'common.news',
            seo_title: 'News',
            icon: PaperClipOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'news_add',
                    component: import('../views/admin-views/news/add'),
                    path: `${APP_PREFIX_PATH}/news/add`,
                    title: 'admin.news.title_add',
                    seo_title: 'Nieuws toevoegen',
                    icon: PaperClipOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'news_edit',
                    component: import('../views/admin-views/news/edit'),
                    path: `${APP_PREFIX_PATH}/news/edit/:id`,
                    title: 'admin.news.title_edit',
                    seo_title: 'Nieuws bewerken',
                    icon: PaperClipOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
    ],
    partner: [
        {
            key: 'home',
            component: import('../views/partner-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'common.home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'schedules',
            component: import('../views/partner-views/schedules/index'),
            path: `${APP_PREFIX_PATH}/schedules`,
            title: 'partner.schedules',
            seo_title: 'Roosters',
            icon: ScheduleOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_SCHEDULER'],
            },
            submenu: [
                {
                    key: 'schedules_add',
                    component: import('../views/partner-views/schedules/add'),
                    path: `${APP_PREFIX_PATH}/schedules/add`,
                    title: 'partner.schedules.title_add',
                    seo_title: 'Rooster toevoegen',
                    icon: ScheduleOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'schedules_edit',
                    component: import('../views/partner-views/schedules/edit'),
                    path: `${APP_PREFIX_PATH}/schedules/edit/:id`,
                    title: 'partner.schedules.title_edit',
                    seo_title: 'Rooster bewerken',
                    icon: ScheduleOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'lessons',
            component: import('../views/partner-views/lessons/index'),
            path: `${APP_PREFIX_PATH}/lessons`,
            title: 'partner.lessons',
            seo_title: 'Lessen',
            icon: RocketOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_SCHEDULER'],
            },
            submenu: [
                {
                    key: 'lessons_add',
                    component: import('../views/partner-views/lessons/add'),
                    path: `${APP_PREFIX_PATH}/lessons/add`,
                    title: 'partner.lessons.title_add',
                    seo_title: 'Les toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'lessons_edit',
                    component: import('../views/partner-views/lessons/edit'),
                    path: `${APP_PREFIX_PATH}/lessons/edit/:id`,
                    title: 'partner.lessons.title_edit',
                    seo_title: 'Les bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'bookings',
            component: import('../views/partner-views/bookings/index'),
            path: `${APP_PREFIX_PATH}/bookings`,
            title: 'partner.bookings',
            seo_title: 'Boekingen',
            icon: TeamOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'closed-days',
            component: import('../views/partner-views/closed-days/index'),
            path: `${APP_PREFIX_PATH}/closed-days`,
            title: 'partner.closed_days',
            seo_title: 'Gesloten dagen',
            icon: CalendarOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_SCHEDULER'],
            },
            submenu: [
                {
                    key: 'closed-days_add',
                    component: import('../views/partner-views/closed-days/add'),
                    path: `${APP_PREFIX_PATH}/closed-days/add`,
                    title: 'partner.closed_days.title_add',
                    seo_title: 'Gesloten dag toevoegen',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'closed-days_edit',
                    component: import('../views/partner-views/closed-days/edit'),
                    path: `${APP_PREFIX_PATH}/closed-days/edit/:id`,
                    title: 'partner.closed_days.title_edit',
                    seo_title: 'Gesloten dag bewerken',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'company-data-edit',
            component: import('../views/partner-views/profile/company'),
            path: `${APP_PREFIX_PATH}/partner/edit`,
            title: 'partner.company_data',
            seo_title: 'Bedrijfsgegevens',
            icon: HomeOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER'],
            },
            submenu: [],
        },
        {
            key: 'invoices',
            component: import('../views/shared-views/invoices/index'),
            path: `${APP_PREFIX_PATH}/invoices`,
            title: 'common.invoices',
            seo_title: 'Specificaties',
            icon: EuroOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_FINANCE'],
            },
            submenu: [],
        },
        {
            key: 'profile-edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'common.profile_edit',
            seo_title: 'Profiel bewerken',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: [],
        },
        {
            key: 'locations',
            component: import('../views/partner-views/locations/index'),
            path: `${APP_PREFIX_PATH}/locations`,
            title: 'common.locations',
            seo_title: 'Locaties',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: [
                {
                    key: 'locations_add',
                    component: import('../views/partner-views/locations/add'),
                    path: `${APP_PREFIX_PATH}/locations/add`,
                    title: 'partner.locations.title_add',
                    seo_title: 'Locatie toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: 'locations_edit',
                    component: import('../views/partner-views/locations/edit'),
                    path: `${APP_PREFIX_PATH}/locations/edit/:id`,
                    title: 'partner.locations.title_edit',
                    seo_title: 'Locatie bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
            ],
        },
        {
            key: 'connectors',
            component: import('../views/partner-views/connectors/index'),
            path: `${APP_PREFIX_PATH}/connectors`,
            title: 'common.connectors',
            seo_title: 'Softwarepartijen',
            icon: ApiOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: [],
        },
        {
            key: 'settings',
            component: import('../views/partner-views/settings/index'),
            path: `${APP_PREFIX_PATH}/settings`,
            title: 'common.settings',
            seo_title: 'Instellingen',
            icon: SettingOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: [],
        },
    ],
    company: [
        {
            key: 'home',
            component: import('../views/company-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'common.home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'users',
            component: import('../views/company-views/users/index'),
            path: `${APP_PREFIX_PATH}/users`,
            title: 'common.participants',
            seo_title: 'Deelnemers',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'company',
            component: import('../views/company-views/company/edit'),
            path: `${APP_PREFIX_PATH}/company`,
            title: 'common.company_data',
            seo_title: 'Bedrijfsgegevens',
            icon: HomeOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'profile-edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'common.profile_edit',
            seo_title: 'Profiel bewerken',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: [],
        },
    ],
    partnerChains: [
        {
            key: 'home',
            component: import('../views/partner-chain-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'common.home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'sales',
            component: import('../views/partner-chain-views/sales/index'),
            path: `${APP_PREFIX_PATH}/sales`,
            title: 'common.sales',
            seo_title: 'Omzet',
            icon: EuroOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'bookings',
            component: import('../views/partner-chain-views/bookings/index'),
            path: `${APP_PREFIX_PATH}/bookings`,
            title: 'common.bookings',
            seo_title: 'Boekingen',
            icon: CalendarOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
        {
            key: 'invoices',
            component: import('../views/shared-views/invoices/index'),
            path: `${APP_PREFIX_PATH}/invoices`,
            title: 'common.invoices',
            seo_title: 'Specificaties',
            icon: EuroOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [],
        },
    ],
};

export default navigationConfig;
