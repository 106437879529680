import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import navigationConfig from 'configs/NavigationConfig';
import IntlMessage from 'components/util-components/IntlMessage';
import { connect } from 'react-redux';
import userService from '../../services/UserService';

let breadcrumbData = {
    '/app': <IntlMessage id="common.home" />,
};

const BreadcrumbRoute = withRouter(props => {
    const { location } = props;
    const pathName = location.pathname.replace(/\/([0-9]+)/g, '/:id');
    const pathSnippets = pathName.split('/').filter(i => i);
    const buildBreadcrumb = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        if (breadcrumbData[url]) {
            return (
                <Breadcrumb.Item key={url}>
                    <Link to={url}>{breadcrumbData[url]}</Link>
                </Breadcrumb.Item>
            );
        } else {
            return '';
        }
    });

    return (
        <div>
            <Breadcrumb>{buildBreadcrumb}</Breadcrumb>
        </div>
    );
});

export class AppBreadcrumb extends Component {
    getRoutes() {
        const role = userService.getRoleType(this.props.user.roles);
        navigationConfig[role].forEach((elm, i) => {
            const assignBreadcrumb = obj =>
                (breadcrumbData[obj.path] = <IntlMessage id={obj.title} />);
            assignBreadcrumb(elm);
            if (elm.submenu) {
                elm.submenu.forEach(elm => {
                    assignBreadcrumb(elm);
                    if (elm.submenu) {
                        elm.submenu.forEach(elm => {
                            assignBreadcrumb(elm);
                        });
                    }
                });
            }
        });
    }

    render() {
        this.getRoutes();

        return <BreadcrumbRoute user={this.props.user} />;
    }
}

const mapStateToProps = ({ auth }) => {
    const { user } = auth;
    return { user };
};

export default connect(mapStateToProps, null)(AppBreadcrumb);
