export const API_BASE_URL = 'https://127.0.0.1:8000/api';
export const API_LIMIT = 30; // limit set in API

export const API_PORTAL_USER_ROLES = [
    'ROLE_ADMIN',
    'ROLE_PARTNER',
    'ROLE_PARTNER_FINANCE',
    'ROLE_PARTNER_SCHEDULER',
    'ROLE_CONNECTOR',
    'ROLE_COMPANY',
];
