import { Divider, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import ScopeSwitcher from '../ScopeSwitcher';
import { HomeOutlined } from '@ant-design/icons';
import useFetch from '../../../hooks/useFetch';

/**
 * Profile card component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileCard = props => {
    const { scope } = useSelector(state => state.auth);
    const { data, isLoading } = useFetch(scope);

    if (!scope) {
        return null;
    }

    // extract correct address
    let address = null;
    if (data) {
        if (typeof data.locations !== 'undefined' && data.locations.length) {
            // get the default location
            let loc = data.locations.find(loc => loc.useAsDefault);
            if (!loc) {
                loc = data.locations[0];
            }

            address = (
                <div>
                    {(loc.street ?? '') +
                        ' ' +
                        loc.housenumber +
                        (loc.housenumberAddon ? loc.housenumberAddon : '')}
                    <br />
                    {loc.postcode + ' ' + loc.city}
                </div>
            );
        } else if (data.street) {
            address = (
                <div>
                    {data.street +
                        ' ' +
                        data.housenumber +
                        (data.housenumberAddon ? data.housenumberAddon : '')}
                    <br />
                    {data.postcode + ' ' + data.city}
                </div>
            );
        }
    }

    return (
        <div>
            <div className={'pl-4 pr-4 pt-4'}>
                {isLoading && <Skeleton />}
                {!isLoading && props.collapsed ? (
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <HomeOutlined />
                    </div>
                ) : null}
                {!isLoading && !props.collapsed ? (
                    <div>
                        <ScopeSwitcher />
                        <div className={'mt-3'}>{address}</div>
                    </div>
                ) : null}
            </div>
            <Divider className={'mb-0'} />
        </div>
    );
};

export default ProfileCard;
