import fetch from 'auth/FetchInterceptor';

const userService = {};

userService.getUser = function (params) {
    return fetch({
        url: '/login_check',
        method: 'get',
        params,
    });
};

/**
 * Get the correct role type
 * @param roles
 * @returns {string}
 */
userService.getRoleType = function (roles) {
    if (roles) {
        if (roles.includes('ROLE_ADMIN')) {
            return 'admin';
        }

        if (roles.includes('ROLE_COMPANY')) {
            return 'company';
        }

        if (roles.includes('ROLE_PARTNER_CHAIN')) {
            return 'partnerChains';
        }

        if (roles.some(elm => elm.indexOf('ROLE_PARTNER') > -1)) {
            return 'partner';
        }
    }
    return 'user';
};

/**
 * Check if user is allowed to access with specific roles
 * @param user
 * @param allowedRoles
 * @returns {boolean}
 */
userService.isAllowed = function (user, allowedRoles) {
    let allowed = false;
    if (user.roles) {
        user.roles.forEach(role => {
            if (allowedRoles.includes(role)) {
                allowed = true;
            }
        });
    }

    return allowed;
};

userService.getUserLanguage = function (user) {
    if (!user) {
        return 'Nederlands';
    }
    if (user.locale) {
        if (user.locale === 'en') {
            return 'Engels';
        }
    }
    return 'Nederlands';
};

userService.getProfilePicture = function (user) {
    if (!user) {
        return '';
    }
    if (typeof user.profilePicture === 'string' && user.profilePicture.indexOf('http') >= 0) {
        return user.profilePicture;
    } else {
        return `data:image/jpeg;base64, ${user.profilePicture}`;
    }
};

userService.requestPassword = function (params) {
    return fetch({
        url: '/request-password',
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-Type': 'application/json',
        },
        data: params,
    });
};

userService.setPassword = function (params) {
    return fetch({
        url: '/set-password',
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-Type': 'application/json',
        },
        data: params,
    });
};

userService.resetPassword = function (params) {
    return fetch({
        url: '/login_check',
        method: 'get',
        data: params,
    });
};

userService.approve = function (id, params) {
    return fetch({
        url: '/companies/user/approve/' + id,
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-Type': 'application/json',
        },
        data: params,
    });
};

userService.deny = function (id, params) {
    return fetch({
        url: '/companies/user/deny/' + id,
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-Type': 'application/json',
        },
        data: params,
    });
};

export default userService;
