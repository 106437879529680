import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="page" />}>
            <Switch>
                <Route
                    path={`${AUTH_PREFIX_PATH}/login`}
                    component={lazy(() => import(`./authentication/login`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/choose-scope`}
                    component={lazy(() => import(`./authentication/choose-scope`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/wachtwoord-vergeten`}
                    component={lazy(() => import(`./authentication/forgot-password`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/wachtwoord-herstellen/:hash`}
                    component={lazy(() => import(`./authentication/set-password`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/app/inloggen`}
                    component={lazy(() => import(`./authentication/app-account-ready`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/app/wachtwoord-vergeten`}
                    component={lazy(() => import(`./authentication/app-password-forgot`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/app/wachtwoord-gewijzigd`}
                    component={lazy(() => import(`./authentication/app-password-has-been-set`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/approve-user/:hash/:id`}
                    component={lazy(() => import(`./authentication/register-users/approve`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/deny-user/:hash/:id`}
                    component={lazy(() => import(`./authentication/register-users/deny`))}
                />

                {/* sign up */}
                <Route
                    path={`${AUTH_PREFIX_PATH}/wachtwoord-instellen/:hash`}
                    component={lazy(() => import(`./authentication/create-password`))}
                />

                {/* deprecated - moved to workit.nl */}
                <Route
                    path={`${AUTH_PREFIX_PATH}/payment-extra/:paymentUuid/:hash`}
                    component={lazy(() => import(`./payment/extra`))}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/payment/:hash`}
                    component={lazy(() => import(`./payment/status`))}
                />

                <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
            </Switch>
        </Suspense>
    );
};

export default AppViews;
