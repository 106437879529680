import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = props => {
    const { align, cover, size } = props;
    const Icon = <LoadingOutlined style={{ fontSize: size === 'small' ? 18 : 35 }} spin />;
    return (
        <div className={`loading text-${align} cover-${cover}`}>
            <Spin indicator={Icon} />
        </div>
    );
};

Loading.propTypes = {
    size: PropTypes.string,
    cover: PropTypes.string,
};

Loading.defaultProps = {
    align: 'center',
    cover: 'inline',
};

export default Loading;
