const dev = {
    API_ENDPOINT_URL: 'https://localhost:19043',
};

const prod = {
    API_ENDPOINT_URL: 'https://api.workit.nl',
};

const sandbox = {
    API_ENDPOINT_URL: 'https://sandbox.api.workit.nl',
};

const preview = {
    API_ENDPOINT_URL: 'https://staging.api.workit.nl',
};

const getEnv = () => {
    switch (process.env.REACT_APP_VERCEL_ENV) {
        case 'preview':
            return preview;
        case 'production':
            return prod;
        case 'sandbox':
            return sandbox;
        case 'dev':
        default:
            return dev;
    }
};

export const env = getEnv();
